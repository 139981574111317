import React from "react";
import { BsTwitter, BsLinkedin } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a
        href="https://twitter.com/your_twitter_handle"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsTwitter />
      </a>
    </div>

    <div>
      <a
        href="https://www.facebook.com/your_facebook_profile"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FaFacebookF />
      </a>
    </div>

    <div>
      <a
        href="https://www.linkedin.com/in/martin-kyalo-654111239/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <BsLinkedin />
      </a>
    </div>
  </div>
);

export default SocialMedia;
