import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import { images } from "../../constants";
import { AppWrap, MotionWrap } from "../../wrapper";
import { client } from "../../client";
import "./Footer.scss";

const Footer = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailStatus, setEmailStatus] = useState("");
  const [projects, setProjects] = useState([]);

  const { name, email, message } = formData;

  useEffect(() => {
    const fetchProjects = async () => {
      const query = '*[_type == "projects"]';
      const data = await client.fetch(query);
      setProjects(data);
    };

    fetchProjects();
  }, []);

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const templateParams = {
      name: formData.name,
      email: formData.email,
      message: formData.message,
    };

    emailjs
      .send(
        "service_wz945as",
        "template_2z5rned",
        templateParams,
        "MOO0pynDATtfb4So3"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoading(false);
          setIsFormSubmitted(true);
          setEmailStatus("Success");
          setFormData({ name: "", email: "", message: "" }); // Reset form fields
        },
        (error) => {
          console.log(error.text);
          setLoading(false);
          setIsFormSubmitted(true);
          setEmailStatus("Fail");
        }
      );
  };

  return (
    <div className="footer">
      <h2 className="head-text">Let's Connect and Create Something Amazing!</h2>

      <div className="app__footer-cards">
        <div className="app__footer-card">
          <img src={images.email} alt="email" />
          <a href="mailto:martinkkyalo10@gmail.com" className="p-text">
            martinkkyalo10@gmail.com
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+254-715-836-392" className="p-text">
            +254-715-836-392
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+254-759-669-324" className="p-text">
            +254-759-669-324
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.web2} alt="website" />
          <a href="https://www.martinkyalo.info" className="p-text">
            martinkyalo.info
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.linkedin2} alt="linkedin" />
          <a
            href="https://www.linkedin.com/in/martin-kyalo-654111239"
            className="p-text"
          >
            LinkedIn Profile
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.github2} alt="github" />
          <a href="https://github.com/Martinkkyalo10" className="p-text">
            GitHub Profile
          </a>
        </div>
      </div>

      {!isFormSubmitted ? (
        <form className="app__footer-form app__flex" onSubmit={handleSubmit}>
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="name"
              value={name}
              onChange={handleChangeInput}
              required
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
              required
            />
          </div>
          <div className="app__flex">
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
              required
            />
          </div>
          <button type="submit" className="p-text">
            {!loading ? "Send Message" : "Sending..."}
          </button>
        </form>
      ) : (
        <div>
          {emailStatus === "Success" ? (
            <h3 className="head-text">Thank you for getting in touch!</h3>
          ) : (
            <h3 className="head-text">
              Failed to send your message. Please try again later.
            </h3>
          )}
        </div>
      )}

      <div className="app__footer-projects">
        <h3 className="head-text">Check Out My Projects</h3>
        <ul>
          {projects.map((project) => (
            <li key={project._id}>
              <a href={project.link} target="_blank" rel="noopener noreferrer">
                {project.title}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Footer, "app__footer"),
  "contact",
  "app__whitebg"
);
